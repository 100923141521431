import React, { memo } from "react";
import { Modal, Button, Row, Col, Input, Form, message } from "antd";
import "./contact-form.scss"; // Đảm bảo bạn đã import CSS
import logo from "../../../public/image/logo-nha-nha-khoa-hub.png";
import Image from "next/image";
import wpAxios from "@/src/util/request";
import { BASE_URL } from "@/src/const/const";
import { createContactAdvice } from "@/src/app/service";

const { TextArea } = Input;
const FormItem = Form.Item;

const FormContact = (props) => {
  const { openContactForm, handleOk, handleCancel } = props;
  const [form] = Form.useForm();
  const onFinish = async () => {
    try {
      const filedsValue = await form.validateFields();
      const body = {
        ...filedsValue,
        type: "CLINIC",
      };
      await createContactAdvice(body);
      form.resetFields();
      message.success("Gửi thông tin thành công!");
      handleOk("contactForm");
    } catch (error) {
      message.error("Kiểm tra lại Thông tin đăng bài!");
    }
  };
  return (
    <Modal
      open={openContactForm}
      onOk={onFinish}
      onCancel={() => handleCancel("contactForm")}
      className="wp-contact-form"
      okText="Gửi thông tin"
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Row gutter={24} className="wp-content">
          <Col xs={24}>
            <Image src={logo} alt="NhaKhoaHub" className="logo-nhakhoahub" />
          </Col>
          <Col xs={24} className="wp-description">
            <div className="wp-des">
              <span style={{ color: "#293568" }}>NhaKhoa</span>
              <span style={{ color: "#29C0D3" }}>Hub</span> mong muốn mang đến
              trải nghiệm tốt nhất cho khách hàng trên nền tảng của chúng tôi.
            </div>
            <div className="wp-title">
              Để lại thông tin - nhận ngay tư vấn miễn phí!
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem
              label="Họ và tên"
              name="customer_name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên",
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem
              label="Số điện thoại"
              name="customer_phone_number"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const phoneNumberRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                    if (value && !phoneNumberRegex.test(value)) {
                      return Promise.reject("Số điện thoại không hợp lệ!");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Vấn đề tư vấn"
              name="note"
              style={{ marginBottom: "0px" }}
            >
              <TextArea autoSize={{ minRows: 4, maxRows: 5 }} allowClear />
            </FormItem>
          </Col>
          <Col xs={24}>
            <div className="warning">
              LƯU Ý: Trường có dấu <span style={{ color: "#D41515" }}>*</span>{" "}
              là trường bắt buộc
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(FormContact);
