import { useCallback, useState } from "react";
import { Avatar, Button, Form, Input, message } from "antd";
import { createContactAdvice } from "@/src/app/service";
import { usePathname } from "next/navigation";
import { UserOutlined } from "@ant-design/icons";
import Image from "next/image";
import iconUpdown from "../../../public/icon/icon-up-down.svg";
import iconArrow from "@/public/icon/arrow-float-button.svg";
import chatAlert from "@/public/icon/chat-alert.svg";
import ImageCommon from "../image/image";
import logoNhakhoaHub from "../../../public/image/logo-nhakhoahub-chat.png";
import "./chatbot.scss";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";

const { TextArea } = Input;
const FormItem = Form.Item;

const FormChatbot = ({ currentPage, closeFormChat, classFormChat }) => {
  const { data } = useQuery({
    queryKey: ["api-common"],
  });
  const avatarUser = data?.currentUser?.image[0];
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appendChatBoxThreeDot, setAppendChatBoxThreeDot] = useState(false);

  const onFinish = async () => {
    try {
      const fiedsValue = await form.validateFields();
      setIsLoading(true);
      const body = {
        ...fiedsValue,
        type: "ADMIN",
        created_at: moment(new Date()).format("HH:mm - DD/MM/YYYY"),
        landing_page_url: localStorage?.getItem("firstVisitPage")
          ? localStorage?.getItem("firstVisitPage")
          : window?.location?.href,
        referrer: localStorage?.getItem("referrer")
          ? localStorage?.getItem("referrer")
          : "",
        url: window?.location?.href,
      };
      await createContactAdvice(body);
      setAppendChatBoxThreeDot(true);
      requestAnimationFrame(autoScroll);
      const timer = setTimeout(() => {
        setAppendChatBoxThreeDot(false);
        setIsSuccess(true);
        setIsLoading(false);
        requestAnimationFrame(autoScroll);
      }, 3100);
      return () => clearTimeout(timer);
    } catch (error) {
      message.error("Vui lòng kiểm tra lại thông tin đăng bài!");
    }
  };
  const autoScroll = () => {
    let element = document.querySelector(".chatBody");
    element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
  };

  return (
    <div className="wpFormChatbox">
      <div
        className={`wpFormChat ${classFormChat}`}
        style={{
          bottom: currentPage === "CLINIC_DETAIL" ? "70px" : "",
        }}
      >
        <div className="chatHeader">
          <div>Liên hệ tư vấn</div>
          <Image
            src={iconUpdown}
            alt="iconUpdown"
            onClick={() => {
              closeFormChat();
              setIsSuccess(false);
              form.resetFields();
            }}
          />
        </div>
        <div className="chatBody">
          <div className={"chatContainer"}>
            <Image
              unoptimized
              src={logoNhakhoaHub}
              width={40}
              height={40}
              className="chatBoxAvatar"
              alt="avatar"
            />
            <div className={"rowChat"}>
              <div className={"chatContent"} style={{ width: "fit-content" }}>
                Cảm ơn bạn đã quan tâm đến dịch vụ của{" "}
                <strong>
                  NhaKhoa<span style={{ color: "#29C0D3" }}>Hub</span>!
                </strong>
              </div>
              <div className={"chatContent"}>
                Bạn đang quan tâm đến vấn đề gì? Hãy để lại thông tin, chúng tôi
                sẽ hỗ trợ tư vấn:
              </div>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className={"chatContent"}
              >
                <FormItem
                  name="customer_name"
                  label="Họ và tên của bạn:"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên của bạn",
                    },
                  ]}
                >
                  <Input className="chatBoxInput" style={{ width: "100%" }} />
                </FormItem>
                <FormItem
                  name="customer_phone_number"
                  label="Số điện thoại:"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại của bạn",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const phoneNumberRegex =
                          /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                        if (value && !phoneNumberRegex.test(value)) {
                          return Promise.reject("Số điện thoại không hợp lệ!");
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input className="chatBoxInput" style={{ width: "100%" }} />
                </FormItem>
                <FormItem name="note" label="Vấn đề cần liên hệ:">
                  <TextArea
                    style={{ width: "100%" }}
                    rows={4}
                    autoSize={{ minRows: 3 }}
                  />
                </FormItem>
              </Form>
            </div>
          </div>

          {appendChatBoxThreeDot && (
            <div className={"chatContainer chatBoxThreeDot"}>
              <Image
                unoptimized
                src={logoNhakhoaHub}
                width={40}
                height={40}
                className="chatBoxAvatar"
                alt="avatar"
              />
              <div className={"stage"}>
                <div className={"dot-falling"}></div>
              </div>
            </div>
          )}
          {isSuccess && (
            <div className="chatContainer" style={{ marginTop: "10px" }}>
              <Image
                unoptimized
                src={logoNhakhoaHub}
                width={40}
                height={40}
                className="chatBoxAvatar"
                alt="avatar"
              />
              <div className={"chatContent"}>
                <b>Thật tuyệt vời!</b>
                <div>
                  Anh chị vui lòng để ý điện thoại để được tư vấn tận tình nhé,
                  chúc anh chị một ngày tốt lành
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="chatFooter">
          <div className="wpBtnChatBox">
            <Button
              className={`btnChatBox ${isSuccess ? "disabledBtn" : ""}`}
              style={{ marginBottom: 8 }}
              onClick={onFinish}
              disabled={isSuccess}
              loading={isLoading}
            >
              Gửi yêu cầu
            </Button>
          </div>
          {avatarUser ? (
            <ImageCommon
              data={avatarUser}
              style={"chatBoxAvatar"}
              alt="Avatar icon"
            />
          ) : (
            <Avatar className="imageAvatar" icon={<UserOutlined />} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormChatbot;
