import React, { memo } from "react";
import { Modal, Button, message } from "antd";
import "./phone-number.scss"; // Đảm bảo bạn đã import CSS
import logo from "../../../public/image/logo-nha-nha-khoa-hub.png";
import Image from "next/image";

const PhoneNumber = (props) => {
  const { openPhoneNumber, handleOk, handleCancel, phoneNumber } = props;
  const copyToClipboard = (phone) => {
    navigator.clipboard
      .writeText(phone)
      .then(() => {
        message.success("Số điện thoại đã được sao chép!");
      })
      .catch(() => {
        message.error("Lỗi sao chép số điện thoại.");
      });
  };

  return (
    <Modal
      open={openPhoneNumber}
      onOk={() => handleOk("phoneNumber")}
      onCancel={() => handleCancel("phoneNumber")}
      className="wp-phone-number"
      footer={null}
    >
      <div className="wp-content">
        <Image src={logo} alt="NhaKhoaHub" className="logo-nhakhoahub" />
        <h3 className="title">
          Liên hệ ngay với NhaKhoaHub để được tư vấn miễn phí!
        </h3>
        <h3 className="title">Số điện thoại của chúng tôi:</h3>
        <Button
          onClick={() => copyToClipboard("0976654560")}
          className="btn-phone-number"
        >
          {phoneNumber || "0976 654 560"}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(PhoneNumber);
