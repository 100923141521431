import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\",\"style\":[\"normal\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-cry-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-cry.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-dislike.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-heart-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-heart.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-laugh-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-laugh.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-like.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-thumb-down.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-thumb-up.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/boc-rang-su.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/dan-rang-su.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/nieng-rang-tham-my.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/trong-rang-implant.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/auth-providers.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/client.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/common.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/call-to-action/call-to-action.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/footer/footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/header/header.js");
