"use client";
import styles from "./header.module.scss";
import "./header.scss";
import Image from "next/image";
import logo from "@/public/image/logo-nha-nha-khoa-hub.png";
import logoMobile from "@/public/image/logo-nha-khoa-hub-mobile.png";
import ImgCrop from "antd-img-crop";
import * as NProgress from "nprogress";
import { PREFIX_IMAGE_URL } from "@/src/const/const";
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  Space,
  Avatar,
  Modal,
  Form,
  Upload,
  Input,
} from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
  LogoutOutlined,
  PlusOutlined,
  LoadingOutlined,
  BookOutlined,
} from "@ant-design/icons";
import arrowDownLine from "@/public/icon/arrow-down-line.svg";
import { wrapperRouterPush } from "@/src/util/util";
import { useEffect, useState, useCallback } from "react";
import Link from "next/link";
import { useSession, signIn, signOut } from "next-auth/react";
import { message } from "antd";
import { notification } from "antd";
import { editUserInfo, currentUser } from "@/src/app/service";
import { usePathname, useRouter } from "next/navigation";
import iconCall from "../../../public/icon/icon-call.svg";
import FormContact from "../dialog/contact-form";
import PhoneNumber from "../dialog/phone-number";
import useSessionUser from "@/src/hooks/useSessionUser";
import { BLOG_CATEGORIES, FIND_CLINIC_BY } from "@/src/const/variable";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const getNewBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const FormItem = Form.Item;
const options = {
  enableHighAccuracy: false, // Lower accuracy but faster response
  timeout: 5000, // Timeout after 5 seconds
  maximumAge: 60000, // Use cached location if available within 1 minute
};

const Header = ({ svSession }) => {
  const pathname = usePathname();
  if (pathname == "/embed-get-coupon") return;
  const [form] = Form.useForm();
  const router = useRouter();
  const [openMenu, setOpenMenu] = useState(false);
  const [openServiceMenu, setOpenServiceMenu] = useState(true);
  const [openBlogMenu, setOpenBlogMenu] = useState(true);
  const [modalUser, setModalUser] = useState(false);
  const { data: session, update } = useSession();
  const [fileList, setFileList] = useState([]);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const sessionUser = useSessionUser(svSession, session);
  const [showButtonUpload, setShowButtonUpload] = useState(
    sessionUser?.image[0] ? false : true
  );
  const setupUserInfo = (data) => {
    setFileList(
      data
        ? [
            {
              uid: data.image[0].id,
              name: "Hình ảnh",
              status: "done",
              url: data?.image[0]?.key
                ? `${PREFIX_IMAGE_URL}${data?.image[0]?.key}`
                : data.image[0].link,
            },
          ]
        : []
    );
    form.setFieldsValue({
      avatar: data
        ? [
            {
              uid: data.id,
              name: "Hình ảnh",
              status: "done",
              url: data?.image[0]?.key
                ? `${PREFIX_IMAGE_URL}${data?.image[0]?.key}`
                : data.image[0].link,
            },
          ]
        : [],
      avatarId: data?.image[0].id,
      name: data?.name,
    });
  };
  useEffect(() => {
    setupUserInfo(sessionUser);
  }, [sessionUser]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!localStorage.getItem("firstVisitPage")) {
        localStorage.setItem("firstVisitPage", window.location.href);
        if (document) {
          localStorage.setItem("referrer", document?.referrer);
        }
      }
    }
  }, []);

  const onFinish = async (values) => {
    let dataFieldsValueAvatar;
    setLoadingButton(true);
    if (values.avatar && values.avatar[0]) {
      if (values.avatar[0]?.originFileObj) {
        values.avatar[0].dataBase64 = await getNewBase64(
          values.avatar[0]?.originFileObj
        );
        values.avatar[0].type = values.avatar[0]?.originFileObj.type;
      }
      dataFieldsValueAvatar = values.avatar[0];
    }
    const newImage = [
      {
        link: dataFieldsValueAvatar,
        image_type: "AVATAR_USER",
        id: form.getFieldValue("avatarId"),
      },
    ];

    const body = {
      avatar: newImage,
      name: values.name,
    };

    const result = await editUserInfo(body, session?.user.access_token);
    if (result?.status === 200) {
      message.success("Thành công");
      setModalUser(false);
      setLoadingButton(false);
      const userUpdated = {
        ...session,
        user: {
          ...session.user,
          name: result?.data?.name,
          ...(result?.data?.image?.[0] && {
            image: [...result.data.image],
          }),
        },
      };
      await update(userUpdated);
    } else {
      message.error("Lỗi, vui lòng thử lại!");
      setLoadingButton(false);
    }
  };

  const moveElement = (arr, fromIndex, toIndex) => {
    const element = arr?.splice(fromIndex, 1)[0];
    arr?.splice(toIndex, 0, element);
  };
  const FILTER_BLOG = ["Uncategorized"];
  const arrFilter = BLOG_CATEGORIES.filter(
    (item) => !FILTER_BLOG.includes(item.name)
  );
  const fromIndex = arrFilter?.findIndex(
    (item) => item.name === "review nha khoa"
  );
  moveElement(arrFilter, fromIndex, 1);

  const blogItems = arrFilter?.map((item) => {
    return {
      key: item.term_id.toString(),
      label: (
        <Link
          className={`${
            item?.slug == "kien-thuc-nha-khoa" ||
            item?.slug == "kinh-doanh-nha-khoa"
              ? "hiddenBlock"
              : ""
          }`}
          href={`/blog/${item.slug}`}
          onClick={() => setOpenMenu(false)}
        >
          {item.name}
        </Link>
      ),
    };
  });

  const findClinicItems = FIND_CLINIC_BY.map((item) => {
    return {
      key: item.id.toString(),
      label: (
        <Link href={`/${item.slug}`} onClick={() => setOpenMenu(false)}>
          {item.name}
        </Link>
      ),
    };
  });
  const loginItems = [
    {
      label: (
        <div
          onClick={() => {
            setModalUser(true);
          }}
        >
          <button className="labelSignOut"> Tài khoản</button>
        </div>
      ),
      key: "account",
      icon: <UserOutlined />,
    },
    {
      label: (
        <div
          onClick={() => {
            wrapperRouterPush(router, `/quan-ly-bai-viet`);
          }}
        >
          <button className="labelSignOut"> Quản lý bài viết</button>
        </div>
      ),
      key: "diary",
      icon: <BookOutlined />,
    },
    {
      label: (
        <div
          onClick={() => {
            signOut({ redirect: true }),
              notification.success({
                message: `Bạn đã đăng xuất!`,
                description: "",
                placement: "top",
              });
          }}
        >
          {" "}
          <button className="labelSignOut">Đăng xuất</button>
        </div>
      ),
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const [openContactForm, setOpenContactForm] = useState(false);
  const [openPhoneNumber, setOpenPhoneNumber] = useState(false);
  const handleShowModal = useCallback((type) => {
    const sizeDesktop =
      typeof window !== "undefined" && window.innerWidth > 1200 ? true : false;
    if (!sizeDesktop && type !== "contactForm") {
      window.open("tel:0976654560", "_self");
    } else {
      if (type === "contactForm") setOpenContactForm(true);
      else setOpenPhoneNumber(true);
    }
  }, []);

  const handleOk = useCallback((type) => {
    if (type === "contactForm") setOpenContactForm(false);
    else setOpenPhoneNumber(false);
  }, []);

  const handleCancel = useCallback((type) => {
    if (type === "contactForm") setOpenContactForm(false);
    else setOpenPhoneNumber(false);
  }, []);

  return (
    <>
      <Modal
        open={modalUser}
        title="Thông tin tài khoản"
        onCancel={() => {
          setModalUser(false);
          setupUserInfo(sessionUser);
          setShowButtonUpload(sessionUser?.image[0] ? false : true);
        }}
        maskClosable={false}
        footer={null}
        className="modalUser"
        destroyOnClose
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <FormItem
            label="Ảnh đại diện:"
            name="avatar"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="avatar-user"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length !== 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Bạn chưa chọn ảnh Avatar")
                    );
                  }
                },
              },
            ]}
          >
            <ImgCrop rotationSlider className="modal-edit-upload">
              <Upload
                listType="picture-card"
                maxCount={1}
                onChange={() => {
                  form.setFieldsValue({ avatarId: undefined });
                }}
                showUploadList={{ showPreviewIcon: false }}
                fileList={fileList}
                customRequest={({ onSuccess, file, onError }) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" || file.type === "image/png";
                  if (!isJpgOrPng) {
                    message.error(
                      "Vui lòng tải tệp lên với định dạng JPG và PNG"
                    );
                    setFileList([]);
                    onError();
                  }
                  const isLt2M = file.size / 1024 / 1024 < 7;
                  if (!isLt2M) {
                    message.error("Hình ảnh phải nhỏ hơn 7MB!");
                    setFileList([]);
                    onError();
                  }
                  if (isLt2M && isJpgOrPng) {
                    setShowButtonUpload(false);
                    setFileList([
                      {
                        uid: "-1",
                        name: "image.jpg",
                        status: "done",
                        originFileObj: file,
                        url: window.URL.createObjectURL(file),
                      },
                    ]);
                    form.setFieldValue("avatar", [
                      {
                        uid: "-1",
                        name: "image.jpg",
                        status: "done",
                        originFileObj: file,
                        url: window.URL.createObjectURL(file),
                      },
                    ]);

                    onSuccess();
                  }
                }}
                onRemove={() => {
                  setFileList([]);
                  setShowButtonUpload(true);
                  form.setFieldValue("avatar", null);
                }}
              >
                {showButtonUpload && (
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                        color: "black",
                      }}
                    >
                      Upload
                    </div>
                  </button>
                )}
              </Upload>
            </ImgCrop>
          </FormItem>
          <FormItem
            // label="Tên hiển thị"
            name="name"
            rules={[
              {
                required: true,
                message: "Bạn chưa điền Tên hiển thị",
              },
            ]}
          >
            <Input placeholder="Ví dụ: Nguyễn Hải Anh" />
          </FormItem>
          <FormItem className="wrapper-button-submit">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="button-submit button-blue"
            >
              {loadingButton && <LoadingOutlined />}
              {!loadingButton && "Chỉnh sửa thông tin"}
            </Button>
          </FormItem>
        </Form>
      </Modal>
      <div className={styles.dataHeader}>
        <div className={styles.wrapperHeader}>
          <div className={styles.contentHeader}>
            <div className={styles.wrapperTop}>
              <div className={styles.menuHeader}>
                <div>
                  {openMenu ? (
                    <CloseOutlined
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    />
                  ) : (
                    <MenuOutlined
                      onClick={() => {
                        setOpenMenu(true);
                      }}
                    />
                  )}
                </div>
                <Drawer
                  placement={"left"}
                  open={openMenu}
                  onClose={() => {
                    setOpenMenu(false);
                  }}
                >
                  <div className={"dropdownHeaderMobile"}>
                    <div
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                    >
                      <Link href="/" onClick={() => setOpenMenu(false)}>
                        Trang Chủ
                      </Link>
                    </div>
                    <div
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                    >
                      <span
                        onClick={() => {
                          wrapperRouterPush(router, "/ve-chung-toi");

                          setOpenMenu(false);
                        }}
                      >
                        Về chúng tôi
                      </span>
                    </div>
                    <Link
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                      href="/phong-kham"
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <span>Tìm phòng khám</span>
                    </Link>
                    {
                      <Menu
                        className="menu-clinic"
                        mode={"inline"}
                        items={findClinicItems}
                      ></Menu>
                    }
                    <div
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                    >
                      <Link href="/bac-si" onClick={() => setOpenMenu(false)}>
                        Bác sĩ
                      </Link>
                    </div>
                    <div
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                    >
                      <Link href="/doi-tac" onClick={() => setOpenMenu(false)}>
                        Đối tác
                      </Link>
                    </div>
                    <div
                      className={`dropdownHeaderMobileItem ${
                        !(openBlogMenu || openServiceMenu) ? "opened" : ""
                      }`}
                    >
                      <Link href="/showcase" onClick={() => setOpenMenu(false)}>
                        Showcase
                      </Link>
                    </div>
                    <Link
                      className={`dropdownHeaderMobileItem ${
                        openBlogMenu ? "opened" : ""
                      }`}
                      href="/blog"
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <span>Blog</span>
                    </Link>
                    {
                      <Menu
                        className="menu-blog"
                        mode={"inline"}
                        items={blogItems}
                      ></Menu>
                    }
                    <div
                      className={`${styles.wpAct}`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        className={styles.btnAdvice}
                        onClick={() => handleShowModal("contactForm")}
                        style={{ marginTop: "14px", marginRight: "5px" }}
                      >
                        Nhận tư vấn
                      </Button>
                      <Button
                        icon={
                          <Image
                            src={iconCall}
                            width={29}
                            height={29}
                            alt="liên hệ"
                          />
                        }
                        className={styles.btnCall}
                        onClick={() => handleShowModal("phoneNumber")}
                        style={{ marginTop: "14px" }}
                      >
                        0976 654 560
                      </Button>
                    </div>
                    {!sessionUser && (
                      <div className={styles.wpSignInMobile}>
                        <Link
                          href="/user/login"
                          onClick={() => setOpenMenu(false)}
                          style={{ justifyContent: "start", marginTop: "14px" }}
                        >
                          <Button className="loginButton">Đăng Nhập</Button>
                        </Link>
                        <Link
                          href="/user/register"
                          onClick={() => setOpenMenu(false)}
                          style={{ justifyContent: "start", marginTop: "14px" }}
                        >
                          <Button className="registerButton">Đăng Ký</Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </Drawer>
              </div>
              <Link href="/" className={styles.wpLogo}>
                <Image className={styles.logo} src={logo} alt="NhaKhoaHub" />
                <Image
                  className={styles.logoMobile}
                  src={logoMobile}
                  alt="NhaKhoaHub"
                />
              </Link>
            </div>
            <div className={styles.dropdownHeader}>
              <span className={styles.dropdownLink}>
                <Link href="/ve-chung-toi">
                  <Space>Về chúng tôi</Space>
                </Link>
              </span>
              <Dropdown
                overlayClassName="menu-clinic"
                menu={{ items: findClinicItems }}
              >
                <Link
                  href="/phong-kham"
                  className={styles.dropdownLink}
                  style={{ display: "flex" }}
                >
                  <Space>Tìm phòng khám</Space>
                  <Image
                    src={arrowDownLine}
                    style={{ width: "18px" }}
                    alt="arrow"
                  />
                </Link>
              </Dropdown>
              <span className={styles.dropdownLink}>
                <Link href="/bac-si">
                  <Space>Bác sĩ</Space>
                </Link>
              </span>
              <span className={styles.dropdownLink}>
                <Link href="/doi-tac">
                  <Space>Đối tác</Space>
                </Link>
              </span>
              <span className={styles.dropdownLink}>
                <Link href="/showcase">
                  <Space>Showcase</Space>
                </Link>
              </span>
              <Dropdown
                overlayClassName="menu-blog"
                menu={{ items: blogItems }}
              >
                <Link
                  className={styles.dropdownLink}
                  href="/blog"
                  style={{ display: "flex" }}
                >
                  <Space>Blog</Space>
                  <Image
                    src={arrowDownLine}
                    style={{ width: "18px" }}
                    alt="arrow"
                  />
                </Link>
              </Dropdown>
            </div>
            <div
              className={`${styles.wpAct}`}
              style={{ justifyContent: "start" }}
            >
              <Button
                className={styles.btnAdvice}
                onClick={() => handleShowModal("contactForm")}
              >
                Nhận tư vấn
              </Button>
              <Button
                icon={
                  <Image src={iconCall} width={29} height={29} alt="liên hệ" />
                }
                className={styles.btnCall}
                onClick={() => handleShowModal("phoneNumber")}
              >
                0976 654 560
              </Button>
            </div>
            <div className={styles.wrapperRight}>
              {!sessionUser ? (
                <>
                  <Button
                    style={{ marginRight: "8px" }}
                    href="/user/login"
                    className={`${styles.buttonClinic} button-white`}
                    onClick={() => {
                      localStorage.setItem("rollback-url", pathname);
                    }}
                  >
                    Đăng Nhập
                  </Button>
                  <Button
                    href="/user/register"
                    className={`${styles.buttonClinicRg} button-white`}
                    onClick={() => {
                      localStorage.setItem("rollback-url", pathname);
                    }}
                  >
                    Đăng Ký
                  </Button>
                </>
              ) : (
                <div id="user-dropdown">
                  <Dropdown
                    menu={{ items: loginItems }}
                    // menu={{ items: session ? loginItems : items }}
                    trigger={["click"]}
                    className={styles.avatar}
                    placement="bottomRight"
                    getPopupContainer={() =>
                      document.getElementById("user-dropdown")
                    }
                    overlayStyle={{ marginTop: "60px" }}
                    // styles={{ marginTop:"20px" }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      {sessionUser ? (
                        <Image
                          src={
                            sessionUser.image[0].key
                              ? `${PREFIX_IMAGE_URL}${sessionUser.image[0].key}`
                              : sessionUser.image[0].link
                          }
                          className="avatar-image"
                          width={50}
                          height={50}
                          alt="avatar"
                        />
                      ) : (
                        <Avatar
                          size={50}
                          className="image-user"
                          icon={<UserOutlined />}
                        />
                      )}
                    </a>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FormContact
        openContactForm={openContactForm}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <PhoneNumber
        openPhoneNumber={openPhoneNumber}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default Header;
