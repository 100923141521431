"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import styles from "./footer.module.scss";
import logo from "@/public/image/logo-nha-nha-khoa-hub.png";
import iconphone from "@/public/icon/icon-phone.svg";
import iconfacebook from "@/public/icon/icon-facebook.svg";
import iconzalo from "@/public/icon/icon-zalo.svg";
import imgcertificate from "@/public/image/certificate.png";
import { Col, Row } from "antd";
import Link from "next/link";
import logoFooter from "@/public/image/logo-footer.svg";
import logoFooterMobile from "@/public/image/logo-footer-mobile.png";
import imgDecor1 from "@/public/image/backgr-above.webp";
import imgDecor2 from "@/public/image/backgr-below.webp";
import { usePathname } from "next/navigation";
import Script from "next/script";

const Footer = (props) => {
  const pathname = usePathname();
  if (pathname == "/embed-get-coupon") return;
  const router = useRouter();

  function triggerButton(button) {
    switch (button) {
      case "phone":
        window.open("tel:(+84)961423199", "_self");
        break;
      case "facebook":
        window.open("https://www.facebook.com/nhakhoahub.vn", "_blank");
        break;
      case "zalo":
        window.open("https://zalo.me/0976654560", "_blank");
        break;
    }
  }

  return (
    <div className={styles.wrapperFooter}>
      <Image src={imgDecor1} className={styles.imgDecorAbove} alt="decor" />
      <Image src={imgDecor2} className={styles.imgDecorBelow} alt="decor" />
      <div className={styles.contentFooter}>
        <Row gutter={[30, 0]}>
          <Col span={24}>
            <Link href="/" className={styles.wpLogo}>
              <Image
                src={logoFooter}
                className={styles.logoFirst}
                alt="NhaKhoaHub"
                loading="lazy"
              />
              <Image
                src={logoFooterMobile}
                className={styles.logoSecond}
                alt="NhaKhoaHub"
                loading="lazy"
              />
            </Link>
          </Col>
          <Col xs={24} sm={24} md={12} xl={8}>
            <div className={styles.about}>
              <div>
                <p className={styles.footerHead}>VỀ CHÚNG TÔI</p>
              </div>
              <div>
                <div className={styles.footerIntroduce}>
                  <p>
                    NhaKhoaHub - Nền tảng review và tìm kiếm nha khoa uy tín
                    hàng đầu Việt Nam
                  </p>
                </div>
                <div className={styles.footerAdd}>
                  <p>
                    <strong>Công ty TNHH Fenik Technologies</strong>
                    <br />
                    Giấy chứng nhận ĐKKD số 0109872256 do Sở Kế hoạch và Đầu tư
                    TP. Hà Nội cấp ngày 28/12/2021
                    {/* <strong>Mã số thuế:</strong> 0109872256 */}
                    <br />
                    <strong>Địa chỉ:</strong> Số 104, ngõ 54 Lê Quang Đạo, Phú
                    Đô, Nam Từ Liêm, Hà Nội
                    <br />
                    <strong>Email:</strong> nhakhoahub@fenik-technologies.com
                    <br />
                    <strong>Hotline:</strong> 0976 654 560
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} xl={5}>
            <div>
              <p className={styles.footerHead}>CHÍNH SÁCH</p>
            </div>
            <div className={styles.wpFooterLink}>
              <Row gutter={[14, 0]}>
                <Col span={24}>
                  <div className={styles.footerLink}>
                    <Link href="/gioi-thieu">Giới thiệu</Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/lien-he-hop-tac">Liên hệ hợp tác</Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/dieu-khoan">Điều khoản sử dụng</Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/chinh-sach-bao-mat">Chính sách bảo mật</Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/giai-quyet-tranh-chap">
                      Giải quyết tranh chấp
                    </Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/quy-che-hoat-dong">Quy chế hoạt động</Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} xl={5}>
            <p className={styles.footerHead}>HỖ TRỢ KHÁCH HÀNG</p>
            <div className={styles.wpFooterLink}>
              <Row gutter={[14, 0]}>
                <Col span={24}>
                  <div className={styles.footerLink}>
                    <Link href="/lien-he-hop-tac">
                      Đăng phòng khám lên NhaKhoaHub
                    </Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/blog/review-nha-khoa">Review nha khoa</Link>
                  </div>
                  <div className={`${styles.footerLink} ${styles.hiddenBlock}`}>
                    <Link href="/blog/kien-thuc-nha-khoa">
                      Kiến thức nha khoa
                    </Link>
                  </div>
                  <div className={`${styles.footerLink} ${styles.hiddenBlock}`}>
                    <Link href="/blog/kinh-doanh-nha-khoa">
                      Kinh doanh nha khoa
                    </Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/tim-theo-tinh">
                      Tìm phòng khám theo khu vực
                    </Link>
                  </div>
                  <div className={styles.footerLink}>
                    <Link href="/tim-theo-dich-vu">
                      Tìm phòng khám theo dịch vụ
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <p className={styles.footerHead}>KẾT NỐI VỚI NHAKHOAHUB</p>
            <div className={styles.socialLink}>
              <button onClick={() => triggerButton("phone")}>
                <Image
                  src={iconphone}
                  className={styles.iconFooter}
                  alt="icon điện thoại"
                  loading="lazy"
                />
              </button>
              <button onClick={() => triggerButton("facebook")}>
                <Image
                  src={iconfacebook}
                  className={styles.iconFooter}
                  alt="icon facebook"
                  loading="lazy"
                />
              </button>
              <button onClick={() => triggerButton("zalo")}>
                <Image
                  src={iconzalo}
                  className={styles.iconFooter}
                  alt="icon zalo"
                  loading="lazy"
                />
              </button>
            </div>
            <div className="wp-dmca">
              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=aa463925-668f-4a98-9a88-2d42ae8a269d"
                title="DMCA.com Protection Status"
                class="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/dmca_protected_16_120.png?ID=aa463925-668f-4a98-9a88-2d42ae8a269d"
                  alt="DMCA.com Protection Status"
                  loading="lazy"
                />
              </a>
              <Script
                src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
                strategy="lazyOnload"
              ></Script>
            </div>
            {/* <div>
              <Image
                src={imgcertificate}
                className={styles.certificateImage}
                alt="bộ công thương"
              ></Image>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Footer;
